import { values, reduce } from 'lodash';

/*
 * =================
 * Table of contents
 * -----------------
 *  I.  Information
 *  II. Statistics
 * =================
 */

/*
 * I. Information
 */

export const informationTypes = {
  TOTAL_TIME: 'TOTAL_TIME',
  PERIOD_LAST_SUBBED_IN: 'PERIOD_LAST_SUBBED_IN',
  TIME_IN_PERIOD_OF_LAST_SUB_IN: 'TIME_IN_PERIOD_OF_LAST_SUB_IN',
  CURRENTLY_ON_THE_COURT: 'CURRENTLY_ON_THE_COURT',
};

export const informationValues = {
  [informationTypes.TOTAL_TIME]: {
    id: informationTypes.TOTAL_TIME,
    type: 'timeInMinutes',
    label: 'Total Time Played (Minutes)',
    fieldKey: 'minutesPlayed',
  },
  [informationTypes.PERIOD_LAST_SUBBED_IN]: {
    id: informationTypes.PERIOD_LAST_SUBBED_IN,
    type: 'period',
    label: 'Period Last Subbed In',
    fieldKey: 'periodOfLastSubIn',
  },
  [informationTypes.TIME_IN_PERIOD_OF_LAST_SUB_IN]: {
    id: informationTypes.TIME_IN_PERIOD_OF_LAST_SUB_IN,
    type: 'time',
    label: 'Time In Period Of Last Sub In',
    fieldKey: 'timeInPeriodOfLastSubIn',
  },
  [informationTypes.CURRENTLY_ON_THE_COURT]: {
    id: informationTypes.CURRENTLY_ON_THE_COURT,
    type: 'boolean',
    label: 'Currently On The Court',
    fieldKey: 'onTheCourt',
  },
};

export const createInformationHeaders = () => values(informationValues);

export const createInformationData = (player) => reduce(
  values(informationTypes),
  (informationData, type) => {
    const fieldKey = informationValues?.[type]?.fieldKey;
    const value = player?.playerStats?.[fieldKey] ?? NaN;
    return { ...informationData, [type]: value };
  },
  {},
);

/*
 * II. Statistics
 */

export const statisticTypes = {
  TWO_POINTERS: 'TWO_POINTERS',
  THREE_POINTERS: 'THREE_POINTERS',
  REBOUNDS: 'REBOUNDS',
  ASSISTS: 'ASSISTS',
  MINUTES: 'MINUTES',
  TOTAL_POINTS: 'TOTAL_POINTS',
  STEALS: 'STEALS',
  BLOCKS: 'BLOCKS',
  FOULS: 'FOULS',
};

export const statisticValues = {
  [statisticTypes.TWO_POINTERS]: {
    id: statisticTypes.TWO_POINTERS,
    label: '2P',
    fieldKey: 'twoPointers',
  },
  [statisticTypes.THREE_POINTERS]: {
    id: statisticTypes.THREE_POINTERS,
    label: '3P',
    fieldKey: 'threePointers',
  },
  [statisticTypes.REBOUNDS]: {
    id: statisticTypes.REBOUNDS,
    label: 'REB',
    fieldKey: 'rebounds',
  },
  [statisticTypes.ASSISTS]: {
    id: statisticTypes.ASSISTS,
    label: 'AST',
    fieldKey: 'assists',
  },
  [statisticTypes.MINUTES]: {
    id: statisticTypes.MINUTES,
    type: 'timeInMinutes',
    label: 'MIN',
    fieldKey: 'minutesPlayed',
  },
  [statisticTypes.TOTAL_POINTS]: {
    id: statisticTypes.TOTAL_POINTS,
    label: 'PTS',
    fieldKey: 'points',
  },
  [statisticTypes.FOULS]: {
    id: statisticTypes.FOULS,
    label: 'F',
    fieldKey: 'fouls',
  },
  [statisticTypes.STEALS]: {
    id: statisticTypes.STEALS,
    label: 'STL',
    fieldKey: 'steals',
  },
  [statisticTypes.BLOCKS]: {
    id: statisticTypes.BLOCKS,
    label: 'BLK',
    fieldKey: 'blocks',
  },
};

export const totalStatisticsMap = {
  [statisticTypes.TWO_POINTERS]: 'totalTwoPointers',
  [statisticTypes.THREE_POINTERS]: 'totalThreePointers',
  [statisticTypes.REBOUNDS]: 'totalRebounds',
  [statisticTypes.ASSISTS]: 'totalAssists',
  [statisticTypes.MINUTES]: 'minutesPlayed',
  [statisticTypes.TOTAL_POINTS]: 'totalPoints',
  [statisticTypes.FOULS]: 'totalFouls',
  [statisticTypes.STEALS]: 'totalSteals',
  [statisticTypes.BLOCKS]: 'totalBlocks',
};

export const createStatisticsHeaders = () => values(statisticValues);

export const createPeriodStatisticsData = (period, stats) => reduce(
  values(statisticTypes),
  (periodStatisticsData, type) => {
    const fieldKey = statisticValues?.[type]?.fieldKey;
    const value = stats?.[period]?.[fieldKey] ?? NaN;
    return { ...periodStatisticsData, [type]: value };
  },
  {},
);

export const createStatisticsData = (periods, player) => {
  const statisticsByPeriod = reduce(
    periods,
    (statisticsData, period) => ({
      ...statisticsData,
      [period]: createPeriodStatisticsData(period, player?.playerStats?.periodStats),
    }),
    {},
  );
  const totalStatistics = reduce(
    values(statisticTypes),
    (totals, type) => ({
      ...totals,
      [type]: player?.playerStats?.[totalStatisticsMap?.[type]] ?? NaN,
    }),
    {},
  );
  return {
    ...statisticsByPeriod,
    TOTAL: totalStatistics,
  };
};
