<template>
  <Tooltip
    :text="tooltipText"
    v-if="event.eventType !== 'REPLAY'"
  >
    <div class="mapped-cell--root">
      <span
        v-if="!event.isMapped"
        @click.stop="setEventForMapping(event)"
        :class="[
          'icon-wrapper',
          { 'icon-wrapper--not-mapped': !event.isMapped },
        ]"
      >
        <Icon :name="'link-broken'" />
      </span>
      <span v-else>
        {{ getMappings() }}
      </span>
    </div>
  </Tooltip>
  <div v-else>
    -
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  includes, some, map, sortBy,
} from 'lodash';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import { getAllowedRoles } from '@/../config/userRoles';

export default {
  components: {
    Tooltip,
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const tooltipText = computed(() => (props.event.isMapped ? 'Event mapped' : 'Event not mapped'));

    const requiredRoles = getAllowedRoles('mapping');
    const hasValidRole = computed(() => some(requiredRoles, (role) => includes(store.getters.userRoles, role)));

    const setEventForMapping = (event) => {
      if (event.isMapped || !hasValidRole.value) return;
      const eventData = {
        sportLabel: event.sport.sportLabel,
        row: {
          id: event.eventId,
          name: event.eventName,
          competitionName: event.competition.competitionName,
          startsAt: event.startsAt,
        },
      };
      store.dispatch('setEventListMappingEvent', eventData);
    };

    const mappingOptions = ref({
      betradar: 'BR',
      lsports: 'LS',
      'sportsdata.io': 'SR',
      betgenius: 'BG',
    });
    const getMappings = () => {
      if (!props.event.mappings) return '';
      const sortedMappings = sortBy(props.event.mappings, 'feed');
      return map(sortedMappings, (mapping) => mappingOptions.value[mapping.feed] || '-').join('/');
    };

    return {
      tooltipText,
      setEventForMapping,
      getMappings,
    };
  },
};
</script>

<style lang="scss">
.event-table .table-body .table-cell.table-cell--mapped {
  .mapped-cell--root {
    display: flex;
    align-items: center;
  }

  .icon-wrapper {
    .icon path {
      fill: $black;
    }
  }

  .icon-wrapper.icon-wrapper--not-mapped {
    .icon path {
      fill: $error500;
    }
  }
}
</style>
