<template>
  <div
    class="columns-sidebar"
    @drop="onDrop()"
    @dragover.prevent
  >
    <div
      v-for="column in columns"
      :key="column.key"
      class="column-item"
      draggable="true"
      @dragstart="onDrag($event, column)"
      @dragover="onDragOver(column)"
    >
      <Icon
        class="hamburger-bar-icon"
        name="hamburger-bar"
      />
      <Checkbox
        :model-value="column.visible"
        @update:modelValue="toggleColumnVisibility(column)"
      />
      <div class="column-label">
        {{ column.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { filter, set, findIndex } from 'lodash';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Icon,
    Checkbox,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const allColumns = computed(() => filter(store.getters.columns, ({ untoggleable }) => !untoggleable));
    const columns = computed(() => filter(allColumns.value, (c) => c.toggleable !== false));
    const reorderContext = {};
    const updateBetTickerColumnConfiguration = () => {
      if (route.name === 'bet-ticker') {
        store.dispatch('updateUserConfigurationBetTickerColumns');
      }
    };

    const onDrag = (event, column) => {
      set(event, 'dataTransfer.dropEffect', 'none');
      set(event, 'dataTransfer.effectAllowed', 'move');
      reorderContext.selected = '';
      reorderContext.target = '';
      reorderContext.selected = column;
    };
    const onDragOver = (column) => {
      reorderContext.target = column;
    };
    const onDrop = () => {
      const fromIndex = findIndex(allColumns.value, (column) => column.order === reorderContext.selected.order);
      const toIndex = findIndex(allColumns.value, (column) => column.order === reorderContext.target.order);
      store.dispatch('reorderEventListField', {
        key: reorderContext.selected.key,
        from: fromIndex,
        to: toIndex,
      });
      updateBetTickerColumnConfiguration();
    };
    const toggleColumnVisibility = (column) => {
      store.dispatch('updateTableField', {
        key: column.key,
        values: {
          visible: !column.visible,
        },
      });
      updateBetTickerColumnConfiguration();
    };

    return {
      columns,
      onDrag,
      onDragOver,
      onDrop,
      toggleColumnVisibility,
    };
  },
};
</script>
<style lang="scss">
.columns-sidebar {
  height: 100%;
  padding: 20px 16px 0 16px;
  .column-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .hamburger-bar-icon {
      cursor: grab;
    }
    .dragging {
      .hamburger-bar-icon {
        cursor: grabbing;
      }
    }

    .checkbox { margin-left: 14px; }
    .column-label { margin-left: 4px; }
  }
}
</style>
