<template>
  <div
    :class="[
      'chip',
      `chip--${variant}`,
      { 'chip--small': small },
    ]"
  >
    <div class="chip__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { includes } from 'lodash';

export default {
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => includes(['default', 'white', 'blue', 'green', 'blue-offering', 'green-offering', 'split-offering'], value),
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  display: flex;
  align-items: center;
  background-color: $chipBackgroundColor;
  color: $chipTextColor;
  border: $chipBorder;
  border-radius: $chipBorderRadius;
  padding: $chipPadding;
  user-select: none;
  height: 25px;
  white-space: nowrap;

  &.chip--small {
    font-size: 10px;
    height: 20px;
    text-transform: uppercase;
  }

  &.chip--white {
    background-color: $chipWhiteBackgroundColor;
    border-color: $chipWhiteBorderColor;
  }
  &.chip--blue {
    background-color: $chipBlueBackgroundColor;
    border-color: $chipBlueBorderColor;
  }

  &.chip--green {
    background-color: $chipGreenBackgroundColor;
    border-color: $chipGreenBorderColor;
  }

  &.chip--blue-offering,
  &.chip--green-offering,
  &.chip--split-offering {
    border: unset;
    padding: 2px;

    > .chip__content {
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: inherit;
      padding: $chipPadding;
    }
  }

  &.chip--blue-offering {
    background-color: $primary300;
  }

  &.chip--green-offering {
    background-color: $success300;
  }

  &.chip--split-offering {
    background: linear-gradient(115deg, #749FFF 50%, #66D79A 50%);
  }
}
</style>
