<template>
  <div class="features-cell">
    <span v-if="!bookedKeys.length">
      -
    </span>
    <Tooltip
      v-for="key in bookedKeys"
      :key="key"
      :text="getTooltip(key)"
      left
    >
      <Chip
        :variant="getVariant(key)"
      >
        {{ getLabel(key) }}
      </Chip>
    </Tooltip>
  </div>
</template>

<script>
import { computed } from 'vue';
import { keys, pickBy, identity } from 'lodash';
import Tooltip from '@/components/common/Tooltip';
import Chip from '@/components/common/Chip';

const labelsByKey = {
  core: 'C&D',
  sameGameParlays: 'SGP',
  playerProps: 'PP',
  microMarkets: 'MM',
};

const tooltipsByKey = {
  core: 'Core and Derivative markets',
  sameGameParlays: 'Same Game Parlay',
  playerProps: 'Player Props markets',
  microMarkets: 'Micro Markets',
};

export default {
  components: {
    Tooltip,
    Chip,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const bookedMap = computed(() => pickBy({
      core: props.event.booked,
      sameGameParlays: props.event.booked && props.event.sameGameParlaysBooked,
      playerProps: props.event.booked && props.event.playerPropsBooked,
      microMarkets: props.event.booked && props.event.microMarketsBooked,
    }, identity));
    const bookedKeys = computed(() => keys(bookedMap.value));

    const getLabel = (key) => labelsByKey[key];
    const getTooltip = (key) => tooltipsByKey[key];

    const getOfferingVariant = (nodes) => {
      if (Array.isArray(nodes) && nodes.length > 0) {
        const node = nodes[0];
        if (node?.isOfferedPreMatch && node?.isOfferedLive) {
          return 'split-offering';
        }
        if (node?.isOfferedPreMatch) {
          return 'blue-offering';
        }
        if (node?.isOfferedLive) {
          return 'green-offering';
        }
      }
      return 'default';
    };

    const getVariant = (key) => {
      switch (key) {
      case 'core':
        return getOfferingVariant(props.event?.bookedEvents?.nodes);
      case 'sameGameParlays':
        return getOfferingVariant(props.event?.bookedSameGameParlays?.nodes);
      case 'playerProps':
        return getOfferingVariant(props.event?.bookedPlayerProps?.nodes);
      case 'microMarkets':
        return getOfferingVariant(props.event?.bookedMicroMarkets?.nodes);
      default:
        return 'default';
      }
    };

    return {
      bookedKeys,
      getLabel,
      getTooltip,
      getVariant,
    };
  },
};
</script>

<style lang="scss">
.features-cell {
  display: flex;
}
</style>
