<template>
  <teleport to="body">
    <div
      class="subscription-modal"
    >
      <div
        class="subscription-modal__frame"
        @click.stop="() => {}"
        ref="subscriptionModalRef"
      >
        <div class="subscription-modal__frame-header">
          <div class="subscription-modal__title">
            {{ title }}
          </div>
          <Button
            class="subscription-modal__button"
            variant="tertiary"
            icon="x"
            icon-only
            @click="close"
          />
        </div>
        <div
          v-if="loading"
          class="subscription-modal__frame-content"
        >
          <Spinner
            v-if="loading"
          />
        </div>
        <div
          v-else
          class="subscription-modal__frame-content"
        >
          <div class="subscription-modal__form-group">
            <div class="subscription-modal__form-label market-categories-header">
              <div class="market-categories-title">
                Markets to receive
              </div>
              <div class="market-categories-offering-by-state-title">
                <div class="offering-by-state-title-single">
                  Live
                </div>
                <div class="offering-by-state-title-single">
                  Prematch
                </div>
              </div>
            </div>
            <div class="subscription-modal__form-control">
              <MarketCategoriesListbox
                :model-value="marketCategories"
                :options="marketCategoryOptions"
                :offering-by-game-state="currentRecord?.offeringByGameState"
                @update:model-value="setMarketCategories"
                @update:offeringByGameState="setOfferingByGameState"
              />
            </div>
          </div>
          <div
            v-if="showFeedPriorities"
            class="subscription-modal__form-group"
          >
            <div class="subscription-modal__form-label">
              Feed priority
            </div>
            <div class="subscription-modal__form-control">
              <FeedPriorityList
                :entries="feedPriorities"
                @update:entries="setFeedPriorities"
              />
            </div>
          </div>
        </div>
        <div class="subscription-modal__frame-footer">
          <Button
            variant="tertiary"
            @click="close"
          >
            Cancel
          </Button>
          <Button
            v-if="!loading && confirmLabel"
            variant="primary"
            :disabled="!containsChanges || loading"
            :loading="processing"
            @click="confirm"
          >
            {{ confirmLabel }}
          </Button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import {
  cloneDeep,
  map,
  includes,
  isEqual,
  omit,
  reduce,
} from 'lodash';
import {
  subscriptionTypes,
  pricingModeTypes,
  marketCategoryTypes,
  allMarketCategories,
  allPricingModes,
  fetchSubscriptionRecord,
  saveSubscriptions,
} from '@/services/helpers/subscriptions';
import Button from '@/components/common/Button';
import Spinner from '@/components/common/Spinner';
import MarketCategoriesListbox from './MarketCategoriesListbox';
import FeedPriorityList from './FeedPriorityList';

export default {
  components: {
    Button,
    Spinner,
    MarketCategoriesListbox,
    FeedPriorityList,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const competitionsSubscriptionRoute = computed(() => route.name === 'competitions_subscription');

    const loading = ref(false);
    const processing = ref(false);
    const currentRecord = ref(null);
    const originalRecord = ref(null);

    // General vars
    const type = computed(() => store.getters.subscriptionModalType);
    const id = computed(() => store.getters.subscriptionModalId);
    const title = computed(() => {
      switch (type.value) {
      case subscriptionTypes.COMPETITION:
        return 'Customized subscription';
      case subscriptionTypes.EVENT:
        return 'Customized booking';
      default:
        return 'N/A';
      }
    });
    const confirmLabel = computed(() => {
      if (originalRecord.value?.core) {
        if (!currentRecord.value?.core && type.value === subscriptionTypes.COMPETITION) return 'Unsubscribe';
        if (!currentRecord.value?.core && type.value === subscriptionTypes.EVENT) return 'Unbook';
        return 'Update';
      }
      switch (type.value) {
      case subscriptionTypes.COMPETITION:
        return 'Subscribe';
      case subscriptionTypes.EVENT:
        return 'Book';
      default:
        return '';
      }
    });
    const containsChanges = computed(() => !isEqual(originalRecord.value, currentRecord.value));

    // Market Categories
    const availableMarketCategories = computed(() => map(
      currentRecord.value?.marketCategories || [],
      (marketCategory) => {
        if (marketCategory === 'SGP') return marketCategoryTypes.SAME_GAME_PARLAYS;
        return marketCategory;
      },
    ));
    const marketCategoryOptions = computed(() => map(allMarketCategories, (category) => ({
      ...category,
      disabled: (category.id !== marketCategoryTypes.CORE_AND_DERIVATIVES && !currentRecord.value?.core) || !includes(availableMarketCategories.value, category.id),
    })));
    const marketCategories = computed(() => ({
      [marketCategoryTypes.CORE_AND_DERIVATIVES]: !!currentRecord.value?.core,
      [marketCategoryTypes.SAME_GAME_PARLAYS]: !!currentRecord.value?.sameGameParlays,
      [marketCategoryTypes.PLAYER_PROPS]: !!currentRecord.value?.playerProps,
      [marketCategoryTypes.MICROS]: !!currentRecord.value?.micro,
    }));

    const resetOfferingByGameState = ({
      offeringByGameState,
      resetPreMatch,
      resetLive,
    }) => reduce(
      offeringByGameState,
      (result, value, key) => ({
        ...result,
        [key]: {
          ...value,
          isOfferedPreMatch: resetPreMatch ? false : value.isOfferedPreMatch,
          isOfferedLive: resetLive ? false : value.isOfferedLive,
        },
      }),
      {},
    );

    const resetMarketCategories = () => {
      currentRecord.value = {
        ...currentRecord.value,
        core: false,
        sameGameParlays: false,
        playerProps: false,
        micro: false,
        offeringByGameState: {
          ...resetOfferingByGameState({
            offeringByGameState: currentRecord.value.offeringByGameState,
            resetPreMatch: true,
            resetLive: true,
          }),
        },
      };
    };
    const setMarketCategories = (newMarketCategories) => {
      const isCoreEnabled = newMarketCategories[marketCategoryTypes.CORE_AND_DERIVATIVES];

      // Remove other market categories if core is disabled
      if (currentRecord.value?.core && !isCoreEnabled) {
        resetMarketCategories();
        return;
      }

      currentRecord.value = {
        ...currentRecord.value,
        core: isCoreEnabled,
        sameGameParlays: isCoreEnabled && newMarketCategories[marketCategoryTypes.SAME_GAME_PARLAYS],
        playerProps: isCoreEnabled && newMarketCategories[marketCategoryTypes.PLAYER_PROPS],
        micro: isCoreEnabled && newMarketCategories[marketCategoryTypes.MICROS],
      };
    };

    const setOfferingByGameState = (updatedOfferingState) => {
      if (updatedOfferingState.type === marketCategoryTypes.CORE_AND_DERIVATIVES) {
        if (!updatedOfferingState.isOfferedPreMatch && !updatedOfferingState.isOfferedLive) {
          resetMarketCategories();
        } else if (!updatedOfferingState.isOfferedPreMatch) {
          currentRecord.value.offeringByGameState = resetOfferingByGameState({
            offeringByGameState: currentRecord.value.offeringByGameState,
            resetPreMatch: true,
          });
        } else if (!updatedOfferingState.isOfferedLive) {
          currentRecord.value.offeringByGameState = resetOfferingByGameState({
            offeringByGameState: currentRecord.value.offeringByGameState,
            resetLive: true,
          });
        }

        if (updatedOfferingState.isOfferedPreMatch || updatedOfferingState.isOfferedLive) {
          currentRecord.value.core = true;
        }
      }
      // If both offeringInPrematch and offeringInLive are false, that means that the selected market category is not offered
      if (!updatedOfferingState.isOfferedPreMatch && !updatedOfferingState.isOfferedLive) {
        currentRecord.value[updatedOfferingState.key] = false;
      }
      currentRecord.value.offeringByGameState = {
        ...currentRecord.value.offeringByGameState,
        [updatedOfferingState.key]: omit(updatedOfferingState, 'key'),
      };
    };

    // Feed Priority
    const feedPriorities = computed(() => currentRecord.value?.feedPriorities || []);
    const showFeedPriorities = computed(() => !!originalRecord.value?.core && !!feedPriorities.value.length);
    const setFeedPriorities = (newFeedPriorities) => {
      currentRecord.value.feedPriorities = newFeedPriorities;
    };

    // Actions
    const confirm = () => {
      processing.value = true;
      currentRecord.value.pricingMode = pricingModeTypes.AUTO;
      const previousRecord = cloneDeep(originalRecord.value);
      saveSubscriptions(type.value, originalRecord.value, currentRecord.value)
        .then(() => {
          if (currentRecord.value.core) {
            store.dispatch('addNotification', {
              message: `Successfully ${type.value === subscriptionTypes.EVENT ? 'booked' : 'subscribed to'} ${type.value === subscriptionTypes.EVENT ? 'event' : 'competition'}`,
              type: 'success',
              duration: 5000,
            });
          } else {
            store.dispatch('addNotification', {
              message: `Successfully ${type.value === subscriptionTypes.EVENT ? 'unbooked' : 'unsubscribed from'} ${type.value === subscriptionTypes.EVENT ? 'event' : 'competition'}`,
              type: 'success',
              duration: 5000,
            });
          }
          if (competitionsSubscriptionRoute.value) {
            store.dispatch('loadSidebar');
          }
        })
        .catch((error) => {
          console.error(error);
          if (currentRecord.value.core) {
            store.dispatch('addNotification', {
              message: `Error occurred while ${type.value === subscriptionTypes.EVENT ? 'booking event' : 'subscribing to competition'}. Please try again later.`,
              type: 'error',
              duration: 5000,
            });
          } else {
            store.dispatch('addNotification', {
              message: `Error occurred while ${type.value === subscriptionTypes.EVENT ? 'unbooking event' : 'unsubscribing from competition'}. Please try again later.`,
              type: 'error',
              duration: 5000,
            });
          }
          currentRecord.value = previousRecord.value;
        })
        .finally(() => {
          if (!competitionsSubscriptionRoute.value) store.dispatch('updateSelectedEvents', []);
          store.dispatch('closeSubscriptionModal');
          processing.value = false;
        });
    };
    const close = () => {
      if (loading.value || processing.value) return;
      store.dispatch('closeSubscriptionModal');
    };

    const subscriptionModalRef = ref(null);

    onClickOutside(subscriptionModalRef, () => {
      close();
    });

    onMounted(() => {
      loading.value = true;
      fetchSubscriptionRecord(type.value, id.value)
        .then((record) => {
          currentRecord.value = { ...record };
          originalRecord.value = { ...record };
        })
        .catch((error) => {
          console.error(error);
          currentRecord.value = null;
          originalRecord.value = null;
        })
        .finally(() => {
          loading.value = false;
        });
    });

    return {
      currentRecord,
      originalRecord,
      loading,
      processing,
      title,
      confirmLabel,
      containsChanges,
      marketCategoryOptions,
      marketCategories,
      setMarketCategories,
      allPricingModes,
      feedPriorities,
      showFeedPriorities,
      setFeedPriorities,
      confirm,
      close,
      subscriptionModalRef,
      setOfferingByGameState,
    };
  },
};
</script>

<style lang="scss">
.subscription-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: transparentize(#191414, 0.5);
  stroke: #000;

  &__frame {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    width: 360px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      padding: 16px;
    }

    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 12px 20px;
      gap: 17px;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      padding: 16px;
    }
  }

  &__form {
    &-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      gap: 4px;

      .market-categories-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .market-categories-title {
          width: 70%;
        }
        .market-categories-offering-by-state-title {
          width: auto;
          display: flex;
          align-items: center;
          gap: 16px;
          .offering-by-state-title-single {
            &:nth-child(1) {
              width: auto;
            }
            &:nth-child(2) {
              width: 100%;
            }
          }
        }
      }
    }

    &-label {
      color: #A9A9A9;
      font-family: Rubik;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &-control {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
    }
  }

  &__input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 4px;

    &-label {
      color: #A9A9A9;
      font-family: Rubik;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &-control {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
    }
  }

  &__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #191414;
  }

  &__radio-input {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #191414;
    padding: 8px;
    cursor: pointer;
    user-select: none;

    &--disabled {
      opacity: 0.5;
    }
  }
}
</style>
